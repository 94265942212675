/** @format */

import { useState, useEffect } from "react";
import "./userOptions.scss";
import { connect } from "react-redux";
import { Localized } from "@fluent/react";

import HeaderModal from "headingModal/modal";
import CalendarSettings from "./components/CalendarSettings";
import UserGroupsSettings from "./components/UserGroupsSettings";
import ProfileSettings from "./components/ProfileSettings";
import { IconUser, IconCalendarCheck, LivetoLoader, IconUsers, IconBell } from "components/icons";
import NotificationSettings from "./components/NotificationSettings";

const mapStateToProps = state => {
	return {
		event: state.event,
		connection: state.connection,
		close_modals: state.close_modals,
		guestuser: state.user.guestuser,
		displayType: state.displayType,
		slots: state.schedule.calendar.slots,
		notification_settings: state.user.notification_settings,
		userGroups: state.lobby.user_groups,
	};
};

const mapDispatchToProps = dispatch => ({
	signOut: () => dispatch({ type: "SIGNOUT" }),
});

const SettingsNav = props => {
	const { displayType, settingsView, setSettingsView, guestuser, userGroups } = props;
	return (
		<>
			{displayType === "mobile" ? (
				<div className="settings-nav-mobile">
					<div
						className={`nav-link profile ${settingsView === "profile" ? "active" : ""}`}
						onClick={() => setSettingsView("profile")}
					>
						<IconUser />
					</div>
					{!guestuser && (
						<>
							<div
								className={`nav-link calendar ${settingsView === "calendar" ? "active" : ""}`}
								onClick={() => setSettingsView("calendar")}
							>
								<IconCalendarCheck />
							</div>
							{userGroups && (
								<div
									className={`nav-link calendar ${settingsView === "user_groups" ? "active" : ""}`}
									onClick={() => setSettingsView("user_groups")}
								>
									<IconUsers />
								</div>
							)}
							<div
								className={`nav-link calendar ${settingsView === "notifications" ? "active" : ""}`}
								onClick={() => setSettingsView("notifications")}
							>
								<IconBell />
							</div>
						</>
					)}
				</div>
			) : (
				<div className="settings-nav">
					<div
						className={`profile ${settingsView === "profile" ? "active" : ""}`}
						onClick={() => setSettingsView("profile")}
					>
						<Localized id="settings-navlink-profile">Profile</Localized>
					</div>
					{!guestuser && (
						<>
							<div
								className={`calendar ${settingsView === "calendar" ? "active" : ""}`}
								onClick={() => setSettingsView("calendar")}
							>
								<Localized id="settings-navlink-calendar">Calendar</Localized>
							</div>
							{userGroups && (
								<div
									className={`calendar ${settingsView === "user_groups" ? "active" : ""}`}
									onClick={() => setSettingsView("user_groups")}
								>
									<Localized id="settings-navlink-user-groups">User groups</Localized>
								</div>
							)}
							<div
								className={`calendar ${settingsView === "notifications" ? "active" : ""}`}
								onClick={() => setSettingsView("notifications")}
							>
								<Localized id="settings-navlink-notifications">Notifications</Localized>
							</div>
						</>
					)}
				</div>
			)}
		</>
	);
};

function UserOptions(props) {
	const {
		showUser,
		setShowUser,
		close_modals,
		guestuser,
		displayType,
		notification_settings,
		defaultView,
		userGroups,
	} = props;

	const [success, setSuccess] = useState(false);
	const [showSuccess, setShowSuccess] = useState(false);
	const [showLoading, setShowLoading] = useState(false);
	const [settingsView, setSettingsView] = useState(defaultView);

	useEffect(() => {
		if (close_modals) {
			setShowUser(false);
		}
	}, [close_modals]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (showSuccess) {
			setTimeout(() => {
				setShowSuccess(false);
			}, 2000);
		}
	}, [showSuccess]);

	return (
		<HeaderModal
			header={true}
			modalClassName={`user-options-modal ${displayType}`}
			showModal={showUser}
			setShowModal={setShowUser}
			id="user-profile"
			headingText={<Localized id="userprofile-title">{"User settings"}</Localized>}
		>
			{showLoading && <LoadingScreen />}
			<div className={`settings-container ${displayType}`}>
				<SettingsNav
					guestuser={guestuser}
					settingsView={settingsView}
					setSettingsView={setSettingsView}
					displayType={displayType}
					userGroups={userGroups.length > 0}
				/>

				<div className="content">
					<div className="content-card">
						{settingsView === "profile" && (
							<ProfileSettings
								setSuccess={setSuccess}
								setShowSuccess={setShowSuccess}
								showLoading={showLoading}
								setShowLoading={setShowLoading}
							/>
						)}

						{settingsView === "calendar" && (
							<CalendarSettings
								setSuccess={setSuccess}
								setShowSuccess={setShowSuccess}
								showLoading={showLoading}
								setShowLoading={setShowLoading}
							/>
						)}

						{settingsView === "user_groups" && userGroups.length > 0 && (
							<UserGroupsSettings
								setSuccess={setSuccess}
								setShowSuccess={setShowSuccess}
								showLoading={showLoading}
								setShowLoading={setShowLoading}
								userGroups={userGroups}
							/>
						)}

						{settingsView === "notifications" && (
							<NotificationSettings
								setSuccess={setSuccess}
								setShowSuccess={setShowSuccess}
								showLoading={showLoading}
								setShowLoading={setShowLoading}
								notification_settings={notification_settings}
							/>
						)}
					</div>
				</div>
			</div>

			{showSuccess && (
				<PopUpMessage
					success={success}
					successMessage={<Localized id="userprofile-save-success">Success!</Localized>}
					failureMessage={<Localized id="userprofile-save-failure">Failed..</Localized>}
				/>
			)}
		</HeaderModal>
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(UserOptions);

export const LoadingScreen = props => {
	return (
		<div className="settings-loading-screen">
			<LivetoLoader size={100} />
		</div>
	);
};

export const PopUpMessage = ({ success, successMessage, failureMessage }) => {
	return (
		<div className={`message-popup ${success ? "success" : "failure"}`}>
			{success ? <span>{successMessage}</span> : <span>{failureMessage}</span>}
		</div>
	);
};
