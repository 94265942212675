/** @format */

import { useEffect, useState, forwardRef, useMemo } from "react";
import moment from "moment";
import "./css/calendar.scss";
import { Localized, useLocalization } from "@fluent/react";
import { connect } from "react-redux";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { useGenerateMultilingualData } from "../performers/helpers";
import * as a from "actiontypes";
import SideBar from "./components/SideBar";
import {
	LvtAccordion,
	LvtBox,
	LvtBtn,
	LvtCloseButton,
	LvtModal,
	LvtMultiselect,
	LvtSelect,
	LvtTooltip
} from "components/LvtComponents";
import "moment/locale/en-gb";
import "moment/locale/fi";
import "moment/locale/sv";
import { IconChevronLeft, IconChevronRight } from "components/icons";
import { earliestDate, generateColors, latestDate } from "components/lvtHelpers";
import CalendarAgendaEvent from "./components/CalendarEventAgenda";
import RBCCustomToolbar from "./components/RBCCustomToolbar";
import { useScheduleHistory } from "./helpers/hooks";
export const localizer = momentLocalizer(moment);

export const messages = (is_calendar = false) => ({
	fi: {
		allDay: "Koko päivä",
		previous: <IconChevronLeft />,
		next: <IconChevronRight />,
		today: "Tänään",
		month: "Kuukausi",
		week: "Viikko",
		twodays: "2 päivää",
		schedules: "Ohjelmat",
		day: "Päivä",
		agenda: is_calendar ? "Kaikki tulevat" : "Koko ohjelma",
		date: "Päivämäärä",
		time: "Aika",
		event: "Tapahtuma",
		showMore: total => `+ Näytä lisää (${total})`,
		noEventsInRange: "Tällä aikavälillä ei ole tapahtumia."
	},
	en: {
		allDay: "All day",
		previous: <IconChevronLeft />,
		next: <IconChevronRight />,
		today: "Today",
		month: "Month",
		week: "Week",
		twodays: "2 days",
		schedules: "Ohjelmat",
		day: "Day",
		agenda: is_calendar ? "All upcoming" : "Whole programme",
		date: "Date",
		time: "Time",
		event: "Event",
		showMore: total => `+ Show more (${total})`,
		noEventsInRange: "There are no events in this range."
	},
	se: {
		allDay: "Hela dagen",
		previous: <IconChevronLeft />,
		next: <IconChevronRight />,
		today: "Idag",
		month: "Månad",
		week: "Vecka",
		twodays: "2 päivää",
		schedules: "Ohjelmat",
		day: "Dag",
		agenda: is_calendar ? "Alla kommande" : "Alla händelser",
		date: "Datum",
		time: "Tid",
		event: "Händelse",
		showMore: total => `+ Visa fler (${total})`,
		noEventsInRange: "Det finns inga händelser i detta intervall."
	}
});

const mapDispatchToProps = dispatch => ({
	handleSidebar: payload => dispatch({ type: a.HANDLE_SIDEBAR, payload })
});

const mapStateToProps = state => ({
	schedule: state.schedule.schedule,
	displayType: state.displayType,
	close_modals: state.close_modals,
	sidebar: state.components.sidebar,
	language: state.user.language,
	start_time: state.event.start_time,
	end_time: state.event.end_time
});

export const culture = {
	fi: "fi",
	en: "en",
	se: "sv"
};

const Schedule = props => {
	const {
		showSchedule,
		setShowSchedule,
		sidebar,
		handleSidebar,
		slug,
		schedule = {},
		displayType,
		close_modals,
		language,
		end_time,
		start_time
	} = props;
	const scheduleInfoText = (
		<Localized id="schedule-info-text">
			<div>The times in this program are displayed according to your own time zone</div>
		</Localized>
	);

	const { generate } = useGenerateMultilingualData();

	const { schedules = [] } = schedule;

	const colors = generateColors(schedules.length);

	const [filteredSchedules, setFilteredSchedules] = useState(schedules.map(s => s.id));

	const [eventList, setEventList] = useState([]);
	const [eventListType, setEventListType] = useState("schedules");

	const defaultView = displayType === "mobile" ? "day" : "week";

	const [view, setView] = useState(defaultView);

	const [date, setDate] = useState(new Date(start_time));

	const [goBack, goForward, backDisabled, forwardDisabled, initialize] = useScheduleHistory(
		view,
		setView,
		date,
		setDate,
		filteredSchedules,
		setFilteredSchedules,
		eventListType,
		setEventListType
	);

	/* const defaultDate = () => {
		if (moment(start_time).isAfter(moment())) return new Date(start_time);
		else return new Date();
	}; */

	const [minMax, setMinMax] = useState({
		min: new Date(1972, 0, 1, 0, 0, 0, 0),
		max: new Date(1972, 0, 1, 23, 59, 59)
	});
	const eventLength = moment(end_time).diff(moment(start_time), "days");

	const isLive = (start, end) => {
		return moment().isBetween(moment(start), moment(end));
	};

	const mappedSchedules = schedules.map((s, i) => {
		const { timeslots = [] } = s;
		const start_time = earliestDate(timeslots.map(ts => ts.start_time));
		const end_time = latestDate(timeslots.map(ts => ts.end_time));

		return { ...s, start_time, end_time, color: colors[i] };
	});

	const scheduleList = mappedSchedules
		.filter(s => filteredSchedules.includes(s.id))
		.reduce((list, currSched) => {
			const { start_time, end_time } = currSched;
			if (moment(start_time).isSame(moment(end_time), "day")) {
				const event = {
					id: currSched.id,
					resourceId: currSched.id,
					title: currSched.name,
					start: new Date(currSched.start_time),
					end: new Date(currSched.end_time),
					color: currSched.color,
					data: currSched,
					type: "schedule"
				};
				return [...list, event];
			} else {
				const { timeslots = [] } = currSched;
				const distinctDates = Array.from(
					new Set(
						timeslots.map(ts => {
							const date = new Date(ts.start_time);
							return date.toISOString().split("T")[0]; // Extract only the 'YYYY-MM-DD' part
						})
					)
				);
				let arr = [];
				distinctDates.forEach(dd => {
					let tsArr = timeslots.filter(ts => ts.start_time.split("T")[0] === dd);
					const start = earliestDate(tsArr.map(ts => ts.start_time));
					const end = latestDate(tsArr.map(ts => ts.end_time));
					const newSched = { ...currSched, start, end };
					arr = [...arr, newSched];
				});
				const events = arr.map((s, i) => ({
					id: s.id,
					resourceId: s.id,
					title: s.name,
					start: new Date(s.start),
					end: new Date(s.end),
					color: s.color,
					data: s,
					type: "schedule"
				}));
				return [...list, ...events];
			}
		}, []);

	useEffect(() => {
		if (scheduleList) {
			setEventList(scheduleList);
		}
	}, [scheduleList?.length > 0]);

	useEffect(() => {
		if (eventListType.includes("timeslots")) {
			setEventList(slotList.filter(s => filteredSchedules.includes(s.scheduleId)));
		} else {
			setEventList(scheduleList.filter(s => filteredSchedules.includes(s.id)));
		}
	}, [JSON.stringify(filteredSchedules)]);

	/* useEffect(() => {
		if (view === "agenda") {
			setDate(new Date(start_time));
		}
	}, [view]); */

	const slotList = mappedSchedules
		.filter(s => filteredSchedules.includes(s.id))
		.reduce((prev, s, i) => {
			const { timeslots = [] } = s;
			const mapped = timeslots.map(ts => ({
				id: ts.id,
				title: generate(ts.display_name, ts.name),
				start: new Date(ts.start_time),
				end: new Date(ts.end_time),
				color: s.color,
				slot: ts,
				type: "slot",
				live: isLive(ts.start_time, ts.end_time),
				schedule: s.name,
				scheduleId: s.id,
				resourceId: s.id
			}));
			return [...prev, ...mapped];
		}, []);

	const defaultDate = useMemo(() => {
		let d = new Date();
		if (schedules.length > 0) {
			const combineTimeslots = schedules.reduce((prev, sched) => {
				return [...prev, ...sched.timeslots];
			}, []);

			// Default date
			const starts = combineTimeslots.map(ts => new Date(ts.start_time));
			const earliestDate = Math.min(...starts);

			if (moment(earliestDate).isSameOrAfter(moment(), "day")) {
				d = earliestDate;
			} else {
				const futureStarts = starts.filter(s => moment(s).isAfter(moment()));
				if (futureStarts.length > 0) {
					const earliestFutureDate = Math.min(...futureStarts);
					d = earliestFutureDate;
				}
			}

			// MinMax
			const startTimes = combineTimeslots.map(ts => new Date(ts.start_time).getHours());
			const endTimes = combineTimeslots.map(ts => new Date(ts.end_time).getHours());

			const earliestHour = Math.max(0, Math.min(...startTimes) - 1);
			const latestHour = Math.min(24, Math.max(...endTimes) + 1); // Ensure <= 24

			const minTime = new Date(2024, 0, 1, earliestHour, 0);
			const maxTime = new Date(2024, 0, 1, latestHour === 24 ? 23 : latestHour, 59, 59); // Adjust to end of 23:59 if 24

			setMinMax({ min: minTime, max: maxTime });
		}

		return d;
	}, [JSON.stringify(schedules)]);

	const eventPropGetter = (event, start, end, isSelected) => {
		let backgroundColor = event.color || "lightblue"; // Default color
		let style = { backgroundColor, color: "#000" };

		return {
			style
		};
	};

	useEffect(() => {
		if (close_modals) {
			close();
		}
	}, [close_modals]);

	useEffect(() => {
		handleSidebar({ slot: null, isOpen: false, slotRef: null });
	}, []);

	function close() {
		setShowSchedule(false);
	}

	const handleSelection = event => {
		if (event.type === "schedule") {
			setFilteredSchedules([event.id]);
			setEventListType("timeslots");
			setEventList(slotList.filter(s => s.scheduleId === event.id));
		} else {
			handleSidebar({ isOpen: true, slot: event.slot, slotRef: null });
		}
	};

	const handleEventListType = val => {
		setEventListType(val);
		if (val === "schedules") setEventList(scheduleList);
		else setEventList(slotList);
	};

	const mobileViews = {
		month: false,
		day: true,
		agenda: true
	};
	const desktopViews = {
		month: false,
		day: true,
		week: true,
		agenda: true
	};

	const viewsProps = displayType === "mobile" ? mobileViews : desktopViews;

	useEffect(() => {
		setDate(defaultDate);
	}, [start_time]);

	const reset = () => {
		setEventList(scheduleList);
		setFilteredSchedules(schedules.map(s => s.id));
		setEventListType("schedules");
		setDate(defaultDate);
		if (displayType === "mobile") setView("day");
		else setView("week");
	};

	// Step 1: Create a unique list of days that have events
	const daysWithEvents = [...new Set(eventList.map(event => moment(event.start).format("YYYY-MM-DD")))];

	// Step 2: Filter out empty days in the week view
	const customDateRange = date => {
		const start = moment(date).startOf("week");
		const end = moment(date).endOf("week");

		const visibleDates = [];
		for (let day = start; day <= end; day.add(1, "day")) {
			const formattedDay = day.format("YYYY-MM-DD");
			if (daysWithEvents.includes(formattedDay)) {
				visibleDates.push(new Date(formattedDay));
			}
		}

		return visibleDates;
	};

	const weekSettings = useMemo(() => {
		if (customDateRange(date).length === 0 || view !== "week") return {};
		else {
			return {
				dayPropGetter: date => {
					if (!daysWithEvents.includes(moment(date).format("YYYY-MM-DD"))) {
						return { style: { display: "none" } };
					}
				},
				range: customDateRange,
				dayLayoutAlgorithm: "no-overlap"
			};
		}
	}, [view, JSON.stringify(customDateRange(date))]);

	const resources = useMemo(() => {
		let dateToCompare = date;
		if (view === "week" && customDateRange(date).length === 1) {
			dateToCompare = customDateRange(date)[0];
		}
		return scheduleList
			.filter(s => moment(s.start).isSame(moment(dateToCompare), "day"))
			.map(s => ({ resourceId: s.id, title: s.title }));
	}, [date, view, filteredSchedules.length]);

	const resourceSettings = useMemo(() => {
		const viewCondition = view === "day" || (view === "week" && customDateRange(date).length === 1);
		if (viewCondition && displayType !== "mobile" && resources.length > 0) {
			return {
				resources,
				resourceTitleAccessor: "title",
				resourceIdAccessor: "resourceId",
				dayLayoutAlgorithm: "no-overlap"
			};
		} else return {};
	}, [view, displayType, date, customDateRange(date)]);

	useEffect(() => {
		if (defaultDate && schedules.length > 0 && filteredSchedules.length > 0) {
			initialize();
		}
	}, [defaultDate, schedules.length, filteredSchedules.length]);

	const filtersProps = {
		setFilteredSchedules,
		schedules,
		filteredSchedules,
		colors,
		eventListType,
		handleEventListType,
		displayType
	};

	return (
		<LvtModal
			opened={showSchedule}
			onClose={close}
			size="100%"
			title={
				<div className="schedule-header">
					<div className="schedule-title-container">
						<div className="schedule-title-wrapper">
							<Localized id="calendar-navbar-schedule-link">
								<h2 className="schedule-title">Event Schedules</h2>
							</Localized>
							<LvtTooltip label={scheduleInfoText}>
								<div className="my-auto schedule-info-icon">
									<b>i</b>
								</div>
							</LvtTooltip>
						</div>

						<div className="navigation-buttons">
							<LvtBtn
								onClick={goBack}
								disabled={backDisabled}
								className="schedule-navigation-button"
								data-cy="schedule-navigation-back-btn"
							>
								<IconChevronLeft />
							</LvtBtn>
							<LvtBtn
								onClick={goForward}
								disabled={forwardDisabled}
								className="schedule-navigation-button"
								data-cy="schedule-navigation-forward-btn"
							>
								<IconChevronRight />
							</LvtBtn>
							<LvtBtn onClick={reset} className="schedule-header-button" data-cy="schedule-reset-btn">
								<Localized id="schedule-header-reset-btn">Näytä aloitusnäkymä</Localized>
							</LvtBtn>
						</div>

						<Filters {...filtersProps} />
					</div>
				</div>
			}
			classNames={{ header: "schedule-modal-header" }}
		>
			<div>
				<SideBar
					opened={sidebar.isOpen}
					//handleAddToFavorites={handleAddToFavorites}
					onClose={close}
				/>
				<Calendar
					localizer={localizer}
					culture={culture[language]}
					events={eventList}
					startAccessor="start"
					endAccessor="end"
					style={{ height: "calc(100dvh) - 150px" }}
					eventPropGetter={eventPropGetter}
					onSelectEvent={handleSelection}
					messages={messages(false)[language]}
					defaultView={defaultView}
					views={viewsProps}
					onNavigate={newDate => setDate(newDate)}
					date={date}
					defaultDate={defaultDate}
					onView={newView => setView(newView)}
					view={view}
					{...resourceSettings}
					components={{
						agenda: {
							event: CalendarAgendaEvent
						},
						toolbar: props => <RBCCustomToolbar {...props} />
					}}
					length={eventLength}
					{...minMax}
					{...weekSettings}
				/>
			</div>
		</LvtModal>
	);
};

const Filters = ({ displayType, ...props }) => {
	if (displayType !== "mobile") return <FiltersContent {...props} />;

	return (
		<LvtAccordion
			className="schedule-filters-accordion"
			classNames={{ control: "schedule-filters-accordion-control" }}
			locale="calendar-filter-"
			items={[{ component: <FiltersContent {...props} />, value: "filters", label: "Filters" }]}
		/>
	);
};

const FiltersContent = props => {
	const { setFilteredSchedules, schedules, filteredSchedules, colors, eventListType, handleEventListType } = props;

	const { l10n } = useLocalization();

	const handleFilterSchedules = val => {
		if (val.includes("all")) setFilteredSchedules(schedules.map(s => s.id));
		else {
			setFilteredSchedules(val);
		}
	};

	const selectOptions = () => {
		if (filteredSchedules.length === schedules.length)
			return schedules.map((s, i) => ({ value: s.id, label: s.name, backgroundColor: colors[i], color: "#000" }));
		else
			return [
				{ value: "all", label: "All shedules", backgroundColor: "#FFF", color: "#000" },
				...schedules.map((s, i) => ({ value: s.id, label: s.name, backgroundColor: colors[i], color: "#000" }))
			];
	};

	const SelectItem = forwardRef(({ image, label, description, color, backgroundColor, ...others }, ref) => (
		<div ref={ref} style={{ backgroundColor, color }} {...others}>
			{label}
		</div>
	));

	function Value({ value, label, onRemove, classNames, color, backgroundColor, ...others }) {
		return (
			<div style={{ color }} {...others}>
				<LvtBox
					sx={theme => ({
						display: "flex",
						cursor: "default",
						alignItems: "center",
						backgroundColor: backgroundColor,
						border: `1px solid ${
							theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[4]
						}`,
						paddingLeft: 10,
						borderRadius: 4
					})}
				>
					<LvtBox sx={{ lineHeight: 1, fontSize: 12 }}>{label}</LvtBox>
					<LvtCloseButton
						onMouseDown={onRemove}
						variant="transparent"
						size={22}
						iconSize={14}
						tabIndex={-1}
						style={{ color }}
					/>
				</LvtBox>
			</div>
		);
	}
	return (
		<div className="actions">
			<div className="filters">
				<LvtMultiselect
					data={selectOptions()}
					onChange={handleFilterSchedules}
					label={<Localized id="schedule-select-label">Schedules</Localized>}
					value={filteredSchedules}
					itemComponent={SelectItem}
					valueComponent={Value}
					clearable
					placeholder={l10n.getString("schedule-filter-placeholder", null, "Select schedules")}
					className="schedule-multiselect"
					classNames={{ dropdown: "schedule-multiselect-dropdown" }}
					data-cy="schedule-dropdown"
				/>
				<LvtSelect
					data={[
						{
							value: "timeslots",
							label: l10n.getString("calendar-checkbox-timeslots", null, "Timeslots")
						},
						{
							value: "schedules",
							label: l10n.getString("calendar-checkbox-schedules", null, "Schedules")
						}
					]}
					value={eventListType}
					onChange={handleEventListType}
					label={<Localized id="schedule-type-select-label" />}
				/>
			</div>
		</div>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);
