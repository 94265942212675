import { Localized } from "@fluent/react"
import { LivetoLoader } from 'components/icons';
import { useEditNotificationSettings } from "containers/VirtualEvent/apiCalls";
import { useEffect, useState } from "react";

const NotificationSettings = (props) => {
    const { notification_settings = {}, showLoading, setShowLoading, setShowSuccess, setSuccess } = props
    const [form, setForm] = useState(notification_settings)
    const [disabled, setDisabled] = useState(true)

    const [setSaved, loading] = useEditNotificationSettings(form, setShowSuccess, setSuccess, setDisabled)

    useEffect(() => {
        setShowLoading(loading)
    }, [loading, setShowLoading])

    const handleCheckBox = (e) => {
        const { target } = e
        const { name: key, checked } = target
        setForm(prevValue => {
            const isSound = key.includes('sound')
            const newValue = (!isSound && !checked) ? { [key]: checked, [`${key}_sound`]: checked } : { [key]: checked }
            return {
                ...prevValue,
                ...newValue
            }
        })
        setDisabled(false)
    }

    const handleAll = (e) => {
        const checked = e.target.checked
        const key = e.target.name
        if (!checked && key === 'all') {
            setForm(prevValue => {
                const newValue = setAll(prevValue, false)
                return { ...prevValue, ...newValue }
            })
            setDisabled(false)
            return
        }
        const condition = key === 'all_sound' ? val => val.includes('sound') : val => !val.includes('sound')

        setForm(prevValue => {
            let newValue = {}
            const keys = Object.keys(prevValue).filter(condition)
            keys.forEach(k => newValue[k] = checked)
            return { ...prevValue, ...newValue }
        })
        setDisabled(false)
    }

    const setAll = (obj, val) => {
        let newObj = {}
        Object.keys(obj).forEach(k => newObj[k] = val)
        return newObj
    }

    const allSoundDisabled = () => {
        return Object.keys(form).filter(k => !k.includes('sound')).some(key => form[key] === false)
    }

    const allChecked = (condition) => {
        const keys = Object.keys(form)
        if (condition) return keys.every(key => form[key] === true)
        return keys.filter(k => !k.includes('sound')).every(key => form[key] === true)
    }

    const handleSubmit = () => {
        setSaved(true)
    }

    return (
        <div className="settings-inner-container">
            <div className="heading-row">
                <h5><Localized id="settings-notifications-header">Notifications settings</Localized></h5>
            </div>

            <div className="settings-info">
            <div className="excl-mark">!</div>
            <Localized id="settings-notifications-sound-info">Sound notifications are not working on iOS devices</Localized>
            </div>
            

            <div className="input-row-column-wrapper">
                <table>
                    <thead>
                        <tr className="text-center">
                            <th></th>
                            <th><Localized id="settings-notifications-label" /></th>
                            <th><Localized id="settings-notifications-sound-label" /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(form).filter(k => !k.includes('sound')).map(key =>
                            <TableRow key={`notification_setting_${key}`} type={key} notification={form[key]} sound={form[`${key}_sound`]} handle={handleCheckBox} />
                        )}
                    </tbody>
                    <tfoot>
                        <tr className="check-all">
                            <td><b><Localized id="settings-notifications-all-label" /></b></td>
                            <td className="text-center"><input type="checkbox" name="all" checked={allChecked(false)} onChange={e => handleAll(e)} /></td>
                            <td className="text-center"><input type="checkbox" name="all_sound" checked={allChecked(true)} disabled={allSoundDisabled()} onChange={e => handleAll(e)} /></td>
                        </tr>
                    </tfoot>

                </table>
            </div>
            <div className="footer">
                <div className="button-row">
                    <button disabled={disabled} onClick={handleSubmit}>{
                        showLoading ?
                            <span><LivetoLoader size="30" color="#fff" /></span> :
                            <Localized id="userprofile-save-button">
                                {'Save changes'}
                            </Localized>}
                    </button>
                </div>
            </div>

        </div>
    )
}

const TableRow = (props) => {
    const { type, notification, sound, handle } = props
    return <tr>
        <td >
            <div className="label">
                <Localized id={`settings-notifications-${type}-label`} />
                <span className="setting-tooltip"><Localized id={`settings-${type}-notification-tooltip`} /></span>
            </div>
        </td>
        <td className="text-center"><input type="checkbox" name={type} checked={notification} onChange={e => handle(e)} /></td>
        <td className="text-center"><input type="checkbox" name={`${type}_sound`} disabled={!notification} checked={sound} onChange={e => handle(e)} /></td>
    </tr>
}

export default NotificationSettings